module.exports = {
  siteTitle: 'Snackbar',
  siteDescription: "Neighbourhood cafe, urban farm, co-working space 🥪",
  siteDescriptionLength: 150,
  siteUrl: 'https://snackbarlondon.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  siteKeywords: "",
  author: '', // Organization name used for SEO schema
  userTwitter: '', // Change for Twitter Cards
  shortTitle: '', // Used for App manifest e.g. Mobile Home Screen
  shareImage: 'assets/images/meta-img.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  siteLogo: 'assets/images/logo.png', // Logo used for SEO, RSS, and App manifest
  imageHeight: 800, // Default image sharing size
  imageWidth: 643, // Default image sharing size
  redirectUri: "http://localhost:3000/callback",
  contenfulSpaceId: '8sfap3qtev0t',
  contenfulAccessToken: '1Pgl8OuWoEa7w-1JzKv6JQA0lnZW7fEag6wYgYkPYaI'
}

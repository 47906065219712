import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";
import Home from "./pages/home";
import GrowYourOwn from "./pages/growyourown";

ReactGA.initialize("UA-145902003-1");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <main className="container">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/growyourown" component={GrowYourOwn} />
          </Switch>
        </main>
      </React.Fragment>
    );
  }
}

export default App;

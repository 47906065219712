import React, { Component } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import SEO from "../app/SEO";
import Contentful from "../app/Contentful";
import * as Markdown from "react-markdown";

class Home extends Component {
  state = {
    isLoaded: false,
    entry: [],
    year: "",
    maxcolors: 6,
    classes: "colour-one",
    mailchimpUrl:
      "https://snackbarlondon.us20.list-manage.com/subscribe/post?u=8a77522c5e224b5dfcf2130f5&amp;id=a0e3c9daf1",
  };

  componentDidMount() {
    this.fetchEntry().then(this.setEntry);
  }

  fetchEntry = () =>
    Contentful.getEntries({
      "sys.id[in]": "3elwdO5vQwU51bapMH7w36",
    });

  setEntry = (response) => {
    const year = new Date().getFullYear();
    this.setState({
      isLoaded: true,
      entry: response.items[0],
      year: year,
    });
    this.startBgFade();
  };

  startBgFade = () => {
    const { maxcolors } = this.state;

    setInterval(() => {
      let newFadeStatus = Math.floor(Math.random() * Math.floor(maxcolors));
      if (newFadeStatus === 0) {
        this.setState({
          classes: "colour-one",
          fadestatus: newFadeStatus,
        });
      }
      if (newFadeStatus === 1) {
        this.setState({
          classes: "colour-three",
          fadestatus: newFadeStatus,
        });
      }
      if (newFadeStatus === 2) {
        this.setState({
          classes: "colour-four",
          fadestatus: newFadeStatus,
        });
      }
      if (newFadeStatus === 3) {
        this.setState({
          classes: "colour-five",
          fadestatus: newFadeStatus,
        });
      }
      if (newFadeStatus === 4) {
        this.setState({
          classes: "colour-six",
          fadestatus: newFadeStatus,
        });
      }
    }, 1000);
  };

  render() {
    const { isLoaded, entry, year, classes, mailchimpUrl } = this.state;

    let menus;
    if (isLoaded) {
      menus = entry.fields.textBlock.map(({ fields }, key) => {
        if (fields && fields.upload !== undefined && fields.upload.length) {
          return fields.upload.map((f, i) => (
              <div key={i} className="text-block"><a
                className="btn"
                href={f.fields.file.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {fields.title}
              </a>
              </div>
            ))
        } else {
          if (fields && fields.textArea !== undefined) {
            return (
              <div key={key} className="text-block">
                <Markdown source={fields.textArea} />
                <div dangerouslySetInnerHTML={{ __html: fields.codeBlock }} />
              </div>
            );
          } else {
            return null;
          }
        }
      });
    }

    return (
      <React.Fragment>
        <SEO path={""} content={""} />

        {isLoaded && (
          <div id="wrapper" className={classes}>
            <div className="interior">
              <div id="links">
                <a
                  id="instagram"
                  href="https://www.instagram.com/snackbarlondon/"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-hidden="true"
                >
                  {" "}
                </a>
                <a
                  id="email"
                  href="mailto:info@snackbarlondon.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-hidden="true"
                >
                  {" "}
                </a>
              </div>

              <div id="left-side">
                <div id="mouth"></div>
              </div>

              <div id="right-side">
                <div id="container">
                  <div id="logo"></div>

                  <div className="text-block">
                    <p>{entry.fields.strapline}</p>
                  </div>

                  {menus}

                  <div className="text-block">
                    <h2>Signup to our mailing list</h2>
                    <div id="mailchimp">
                      <MailchimpSubscribe url={mailchimpUrl} />
                    </div>
                  </div>

                  <footer>
                    <a
                      href="https://patio.cool"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Patio <span id="year">{year}</span>
                    </a>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Home;
